<script>
  const currentYear = new Date().getFullYear();

  import { showModal } from "../../scripts/modalStore.js";

  function toggleModal() {
    showModal.update((value) => !value);
  }
</script>

<footer>
  <div class="footer-content">
    <img
      src="/img/beeldmerk-alkebufin.svg"
      alt="Beeldmerk van Alkebufin"
      class="beeldmerk"
    />
    <div class="footer-nav">
      <p>Alkebufin | {currentYear}</p>
      <a href="/privacyverklaring" on:click|preventDefault={toggleModal}
        >Privacyverklaring</a
      >
      <a
        href="/doc/Algemene voorwaarden Alkebufin_updated III.pdf"
        target="_blank">Algemene voorwaarden</a
      >
    </div>
    <span class="version">
      <a
        href="https://faktor22.nl"
        target="_blank"
        aria-label="Bezoek Faktor 22 website">© Faktor 22</a
      > | v.0.2
    </span>
  </div>
</footer>

<style>
  footer {
    margin: 0 auto; /* Centreert de footer */
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    color: #19335e;
    max-width: 1280px;
  }
  footer p,
  footer a {
    font-size: 13px;
  }

  footer a {
    margin-left: 15px;
    color: #19335e;
    text-decoration: underline;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .footer-nav {
    display: flex;
    align-items: center;
  }

  .beeldmerk {
    margin-right: 15px;
    width: 35px;
  }
  .version {
    margin-left: auto; /* Duwt de versie naar de rechterkant */
    background-color: #19335e;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px !important;
  }

  .version a {
    color: #fff;
    text-decoration: none;
    margin-left: 0;
  }
  @media (max-width: 1000px) {
    footer {
      width: 100%;
      padding: 20px 0;
      margin: 0;
    }
    .version {
      padding: 5px 7px;
      font-size: 13px !important;
    }
  }
  @media (max-width: 650px) {
    .footer-nav {
      display: block;
      margin-top: 41px;
      width: 170px;
    }
    footer a {
      margin-left: 0;
    }
    .beeldmerk {
      display: none;
    }
  }
</style>
