<script>
  import { onMount } from "svelte";
  import Header from "./components/Header.svelte";
  import Footer from "./components/Footer.svelte";
  import Modal from "./components/Modal.svelte";
  import { showModal } from "../scripts/modalStore.js";

  let openSection = null;

  function toggleSection(section) {
    openSection = openSection === section ? null : section;
  }

  onMount(() => {
    const slider = document.querySelector(".horizontal-scroll-container");
    if (!slider) return;

    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });

    /* SLIDER BUTTONS */
    // document.addEventListener("DOMContentLoaded", function () {
    //   const container = document.querySelector(".horizontal-scroll-container");
    //   document
    //     .querySelector(".left-btn")
    //     .addEventListener("click", function () {
    //       container.scrollLeft -= 300;
    //     });
    //   document
    //     .querySelector(".right-btn")
    //     .addEventListener("click", function () {
    //       container.scrollLeft += 300;
    //     });
    // });

    document.addEventListener("DOMContentLoaded", function () {
      const container = document.querySelector(".horizontal-scroll-container");

      function smoothScroll(element, change, duration) {
        let start = element.scrollLeft,
          currentTime = 0,
          increment = 20;

        const animateScroll = function () {
          currentTime += increment;
          const val = easeInOutQuad(currentTime, start, change, duration);
          element.scrollLeft = val;
          if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
          }
        };

        animateScroll();
      }

      function easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      }

      document
        .querySelector(".left-btn")
        .addEventListener("click", function () {
          smoothScroll(container, -200, 700);
        });

      document
        .querySelector(".right-btn")
        .addEventListener("click", function () {
          smoothScroll(container, 200, 700);
        });
    });
  });
</script>

<Header />

<main>
  <div class="bg-hero"></div>
  <div class="hero">
    <div class="hero-section-1">
      <h1 class="heading-1">
        <span class="heading-title">Alkebufin Arnhem</span><br />
        Waar administratie en inzicht samenkomen.
      </h1>
      <h2 class="heading-2">
        Alkebufin Arnhem staat voor meer dan alleen cijfers. Wij zijn uw partner
        in heldere administratie en strategisch financieel inzicht.
      </h2>
      <a href="#over-ons" class="cta">Meer informatie →</a>
    </div>
    <div class="hero-section-2">
      <!-- svelte-ignore a11y-img-redundant-alt -->
      <img
        src="/img/alkebufin-bg-hero.svg"
        alt="administratie-afbeelding"
        class="hero-image animate"
      />
    </div>
  </div>

  <div id="diensten"></div>
  <div class="info-section">
    <div class="info-explain">
      <h2>Diensten: kies voor duidelijkheid, kies voor Alkebufin.</h2>
      <p>Persoonlijke aanpak en oplossingen</p>
    </div>

    <div class="scroll-wrapper">
      <button class="scroll-btn left-btn">‹</button>
      <button class="scroll-btn right-btn">›</button>

      <div class="horizontal-scroll-container">
        <div class="info-dienst">
          <img
            src="/img/alkebufin-boekhouding-en-administratie-icon.svg"
            alt="check icon"
            class="icon"
          />
          <h3>Boekhouding & administratie</h3>
        </div>
        <div class="info-dienst">
          <img
            src="/img/alkebufin-samenstellen-jaarrekeningen-icon.svg"
            alt="Samenstellen jaarrekeningen icon"
            class="icon"
          />
          <h3>Samenstellen jaarrekeningen</h3>
        </div>
        <div class="info-dienst">
          <img
            src="/img/alkebufin-periodieke-rapportage-icon.svg"
            alt="Periodieke rapportage icon"
            class="icon"
          />
          <h3>Periodieke rapportage</h3>
        </div>
        <div class="info-dienst">
          <img
            src="/img/alkebufin-inkomstenbelasting-icon.svg"
            alt="Inkomstenbelasting icon"
            class="icon"
          />
          <h3>Inkomstenbelasting</h3>
        </div>
        <div class="info-dienst">
          <img
            src="/img/alkebufin-omzetbelasting-icon.svg"
            alt="Omzetbelasting icon"
            class="icon"
          />
          <h3>Omzetbelasting</h3>
        </div>
        <div class="info-dienst">
          <img
            src="/img/alkebufin-vennootschapbelasting-icon.svg"
            alt="Vennootschapbelasting icon"
            class="icon"
          />
          <h3>Vennootschapbelasting</h3>
        </div>
        <div class="info-dienst">
          <img
            src="/img/alkebufin-zorgtoeslag-icon.svg"
            alt="Zorgtoeslag icon"
            class="icon"
          />
          <h3>Zorgtoeslag</h3>
        </div>
        <div class="info-dienst">
          <img
            src="/img/alkebufin-kindgebonden-budget-icon.svg"
            alt="Kindgebonden budget icon"
            class="icon"
          />
          <h3>Kindgebonden budget</h3>
        </div>
        <div class="info-dienst">
          <img
            src="/img/alkebufin-huurtoeslag-icon.svg"
            alt="Huurtoeslagck icon"
            class="icon"
          />
          <h3>Huurtoeslag</h3>
        </div>
        <div class="info-dienst">
          <img
            src="/img/alkebufin-kinderopvangtoeslag-icon.svg"
            alt="kinderopvangtoeslag icon"
            class="icon"
          />
          <h3>Kinderopvangtoeslag</h3>
        </div>
      </div>
    </div>
    <div id="over-ons"></div>
  </div>

  <div class="margin-top-20 content-section faq width-800">
    <h3 class="font-size-2">Over Ons</h3>
    <p>
      Bij Alkebufin Arnhem draait het niet alleen om het verwerken van cijfers.
      Wij geloven in een holistische benadering van administratie, waarin we
      niet alleen jouw financiële gegevens beheren, maar ook streven naar een
      dieper inzicht in jouw bedrijf en financiële situatie.
    </p>
    <p>
      Onze missie is om meer te zijn dan alleen een administratiekantoor. We
      streven ernaar jouw partner te zijn, die je ondersteunt bij het nemen van
      belangrijke beslissingen door middel van helder financieel inzicht en
      strategische begeleiding. Of je nu een start-up bent die zijn eerste
      stappen zet in de zakenwereld, een gevestigd bedrijf dat zijn financiële
      processen wil optimaliseren, of een ondernemer die op zoek is naar
      professioneel advies, wij zijn er om je te helpen.
    </p>
    <div id="tarieven"></div>
    <p>
      Onze diensten zijn ontworpen om duidelijkheid te bieden in een vaak
      complexe wereld van financiën. We begrijpen dat elke klant uniek is en
      daarom bieden we een persoonlijke aanpak die is afgestemd op jouw
      specifieke behoeften en doelen. Of je nu behoefte hebt aan boekhouding,
      belastingadvies, financiële planning of bedrijfsadvies, wij staan klaar om
      je te ondersteunen.
    </p>
  </div>

  <h3 class="width-800 font-size-2">Tarieven</h3>
  <p class="sub-heading">Eenmanszaak / VOF / BV</p>
  <div class="tarieven">
    <div class="tarief">
      <div class="prijs">
        <h4>Basis</h4>
        <h4 class="bg-prijs">v.a. €67 per maand</h4>
      </div>

      <ul>
        <li>Volledige boekhouding</li>
        <li>BTW/ICP aangifte</li>
        <li>IB aangifte</li>
        <li>VPB aangifte (indien een BV)</li>
        <li>Tot 10 facturen per maand</li>
      </ul>
    </div>
    <div class="tarief margin-lr">
      <div class="prijs">
        <h4>Plus</h4>
        <h4 class="bg-prijs">v.a. €87 per maand</h4>
      </div>
      <ul>
        <li>Volledige boekhouding</li>
        <li>BTW/ICP aangifte</li>
        <li>IB aangifte</li>
        <li>VPB aangifte (indien een BV)</li>
        <li>Jaarrekening</li>
        <li>Tot 20 facturen per maand</li>
      </ul>
    </div>
    <div class="tarief">
      <div class="prijs">
        <h4>Premium</h4>
        <h4 class="bg-prijs">v.a. €137 per maand</h4>
      </div>
      <ul>
        <li>Volledige boekhouding</li>
        <li>BTW/ICP aangifte</li>
        <li>IB aangifte</li>
        <li>VPB aangifte (indien een BV)</li>
        <li>Jaarrekening</li>
        <li>Onbeperkte aantal facturen</li>
        <li>Facturatiemodule & herinneren</li>
      </ul>
    </div>
  </div>

  <div class="losse-diensten width-800">
    <h3 class="font-size-2">Tarieven losse diensten</h3>
    <ul class="sub-price">
      <li>Aangifte Btw <span>v.a. € 138,00</span></li>
      <li>
        Aangifte inkomstenbelasting winst <span
          >v.a € 238,00 per aangifte per vennoot</span
        >
      </li>
      <li>
        Aangifte vennootschapbelasitng <span
          >v.a € 238,00 per aangifte per B.V.</span
        >
      </li>
      <li>Samenstelling jaarrekening <span>prijs op aanvraag</span></li>
      <li>Overige losse tarieven <span>€ 53,00 per uur</span></li>
    </ul>
  </div>

  <div class="particulieren width-800">
    <h3 class="font-size-2">Tarieven particulieren (incl. btw)</h3>
    <ul class="sub-price-1">
      <li>
        Aangifte inkomstenbelasting alleenstaande <span
          >€ 60,00 per aangifte</span
        >
      </li>
      <li>
        Aangifte inkomstenbelasting met partner <span>€ 75,00 per aangifte</span
        >
      </li>
      <li>
        Aangifte inkomstenbelasting studenten/ scholieren <span
          >€ 40,00 per aangifte</span
        >
      </li>
      <li>
        Meerkosten bij scheiding, aan – of verkoop woning, en oversluiten
        hypotheek <span>€ 20,00</span>
      </li>

      <li>
        Voorlopige teruggave (aanvraag/wijzigen) <span
          >€ 60,00 (1 persoon) € 75,00 (gezamenlijk)</span
        >
        In combinatie met een aangifte 20% korting
      </li>
      <li>
        <div id="faq"></div>
        Huurtoeslag/ Zorgtoeslag/ Kinderopvangtoeslag (aanvraag/wijzigen)<span>
          € 40,00 p.p.</span
        >
      </li>
    </ul>
  </div>

  <div class="accordion width-800">
    <h3>Veel gestelde vragen</h3>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section1")}>
        Wat voor soort diensten biedt Alkebufin Arnhem aan?
        <img
          src="/img/icon-down-2.svg"
          alt="Arrow accordion 1"
          class:rotate={openSection === "section1"}
        />
      </div>
      {#if openSection === "section1"}
        <div class="content">
          Alkebufin Arnhem biedt een breed scala aan financiële diensten aan,
          waaronder boekhouding & administratie, het samenstellen van
          jaarrekeningen, periodieke rapportage, inkomstenbelasting,
          omzetbelasting, vennootschapsbelasting, en het aanvragen van toeslagen
          zoals zorgtoeslag, kindgebonden budget, huurtoeslag en
          kinderopvangtoeslag.
        </div>
      {/if}
    </div>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section2")}>
        Hoe kan ik een afspraak maken met Alkebufin Arnhem?
        <img
          src="/img/icon-down-2.svg"
          alt="Arrow accordion 2"
          class:rotate={openSection === "section2"}
        />
      </div>
      {#if openSection === "section2"}
        <div class="content">
          Je kunt eenvoudig een afspraak maken met Alkebufin Arnhem door
          telefonisch contact op te nemen via <a href="tel:0642647251"
            >06-42647251</a
          >
          of door een e-mail te sturen naar
          <a href="mailto:info@alkebufin.nl">info@alkebufin.nl</a>. Ons team
          staat klaar om je te helpen en een afspraak te plannen die past bij
          jouw schema.
        </div>
      {/if}
    </div>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section3")}>
        Welke informatie heb ik nodig voor mijn eerste afspraak?
        <img
          src="/img/icon-down-2.svg"
          alt="Arrow accordion 3"
          class:rotate={openSection === "section3"}
        />
      </div>
      {#if openSection === "section3"}
        <div class="content">
          Voor je eerste afspraak adviseren we je om relevante financiële
          documenten mee te nemen, zoals bankafschriften, belastingaangiften van
          voorgaande jaren, en andere relevante informatie met betrekking tot je
          financiële situatie. Dit stelt ons in staat om een grondige analyse
          uit te voeren en je zo goed mogelijk van dienst te zijn.
        </div>
      {/if}
    </div>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section4")}>
        Wat zijn de tarieven voor de diensten van Alkebufin Arnhem?
        <img
          src="/img/icon-down-2.svg"
          alt="Arrow accordion 4"
          class:rotate={openSection === "section4"}
        />
      </div>
      {#if openSection === "section4"}
        <div class="content">
          Onze tarieven variëren afhankelijk van de specifieke diensten die je
          nodig hebt en de complexiteit van je financiële situatie. Neem gerust
          contact met ons op voor een vrijblijvende offerte op maat.
        </div>
      {/if}
    </div>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section5")}>
        Hoe lang duurt het voordat mijn belastingaangifte wordt verwerkt?
        <img
          src="/img/icon-down-2.svg"
          alt="Arrow accordion 5"
          class:rotate={openSection === "section5"}
        />
      </div>
      {#if openSection === "section5"}
        <div class="content">
          De verwerkingstijd van belastingaangiften kan variëren afhankelijk van
          verschillende factoren, waaronder de drukte bij de belastingdienst en
          de complexiteit van je aangifte. We streven ernaar om je aangifte zo
          snel en efficiënt mogelijk te verwerken en houden je op de hoogte van
          de voortgang.
        </div>
      {/if}
    </div>
    <div class="toggle">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="header" on:click={() => toggleSection("section6")}>
        Biedt Alkebufin Arnhem ook advies op maat voor mijn specifieke situatie?
        <img
          src="/img/icon-down-2.svg"
          alt="Arrow accordion 6"
          class:rotate={openSection === "section6"}
        />
      </div>
      {#if openSection === "section6"}
        <div class="content">
          Ja, wij geloven in een persoonlijke benadering en bieden advies op
          maat dat is afgestemd op jouw unieke behoeften en doelen. Ons ervaren
          team staat klaar om je te voorzien van deskundig advies en begeleiding
          op alle gebieden van financiën en belastingen.
        </div>
      {/if}
    </div>
  </div>

  <div class="content-section width-800">
    <p>
      Wat ons onderscheidt, is onze toewijding aan het leveren van hoogwaardige
      service met een persoonlijke touch. Ons team bestaat uit ervaren
      professionals die niet alleen uitblinken in hun vakgebied, maar ook een
      passie hebben voor het helpen van klanten. We luisteren naar jouw verhaal,
      begrijpen jouw uitdagingen en werken samen met jou aan oplossingen die
      echt werken.
    </p>
    <p>
      Kortom, kies voor duidelijkheid, kies voor Alkebufin Arnhem. We zijn hier
      om je te begeleiden op jouw financiële reis en je te helpen succes te
      behalen in jouw onderneming.
    </p>
  </div>

  <div class="contact width-800">
    <h3>Contact</h3>
    <p>
      Wil je meer weten over onze diensten of een afspraak maken? Neem dan
      gerust contact met ons op. Ons team staat klaar om je te helpen en al je
      vragen te beantwoorden.
    </p>
    <a href="tel:0642647251">Neem contact op →</a>
  </div>
</main>

<Footer />

<Modal show={showModal} />

<style>
  main {
    padding: 1em;
    margin: 0 auto;
  }

  .bg-hero {
    background-color: #e4eefa;
    width: 100%;
    height: 700px;
    position: absolute;
    top: -20px;
    left: 0;
    z-index: -1;
  }

  .hero {
    display: flex;
    justify-content: space-between;
    gap: 150px;
    margin-top: 120px;
  }

  .hero-section-2 {
    align-content: center;
    display: flex;
  }

  .hero-image {
    width: 400px;
    max-width: 600px;
  }

  .cta {
    font-size: 20px;
    margin-top: 10px;
    display: inline-block;
  }

  .margin-top-20 {
    margin-top: 20px;
  }

  .heading-title {
    font-size: 22px;
    font-weight: 300;
  }

  .heading-1 {
    font-size: 50px;
    font-weight: 600;
    color: #19335e;
  }

  .heading-2 {
    font-weight: 300;
    color: #19335e;
  }

  .sub-heading {
    width: 800px;
    max-width: 800px;
    margin: -40px auto 30px auto;
  }

  .icon {
    width: 35px;
    margin-bottom: 5px;
    align-items: center;
  }

  /* Verberg de scrollbar voor Firefox */
  .horizontal-scroll-container {
    scrollbar-width: none;
  }

  /* Verberg de scrollbar voor Chrome en Safari */
  .horizontal-scroll-container::-webkit-scrollbar {
    display: none;
  }

  /* Voeg padding toe om de scrollbar te verbergen, maar behoud scrollfunctionaliteit */
  .horizontal-scroll-container {
    margin: 0 15px;
    display: flex;
    gap: 30px;
    flex: 1;
    overflow-y: hidden;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
  }

  .scroll-btn {
    position: absolute;
    top: 52%;
    transform: translateY(-50%);
    background-color: #19335e;
    color: #fff;
    cursor: pointer;
    width: 34px;
    border-radius: 20px;
    border: none;
    z-index: 1;
  }

  .left-btn {
    left: 5px;
  }

  .right-btn {
    right: 0;
  }

  .scroll-wrapper {
    position: relative;
    overflow: hidden;
  }

  .scroll-wrapper::before,
  .scroll-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 150px; /* Pas dit aan naar wens */
    pointer-events: none;
  }

  .scroll-wrapper::before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
  }

  .scroll-wrapper::after {
    right: 20px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  }

  .info-section {
    display: flex;
    align-items: center;
    margin-top: 90px;
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 10px 10px 20px rgba(25, 51, 94, 0.1);
  }

  .info-explain {
    flex: 0 0 38%;
    margin-right: 40px;
  }

  .info-dienst {
    display: flex;
    /* flex: 0 0 auto; */
    flex-direction: column;
    align-items: center;
    width: fit-content;
    background-color: #eef4fb;
    padding: 30px 15px;
    border-radius: 15px;
    text-align: center;
    height: 90px;
    min-width: 230px;
  }

  .info-dienst h3 {
    margin: 5px;
  }

  .tarieven {
    display: flex;
    justify-content: space-between;
  }

  .tarief {
    flex: 1;
    background-color: #eef4fb;
    padding: 25px;
    border-radius: 20px;
  }

  .margin-lr {
    margin: 0 40px;
  }

  .tarief ul {
    list-style: none;
    padding: 0;
  }

  .tarief ul li {
    margin: 10px 0;
    font-size: 18px;
  }

  .tarief ul li::before {
    content: "✓";
    color: #116dcb;
    display: inline-block;
    width: 1em;
    margin-left: 0em;
    padding-right: 10px;
  }

  .prijs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .prijs h4 {
    font-size: 21px;
    margin: 0 0 10px 0;
  }

  .sub-price,
  .sub-price-1 {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0 20px;
  }

  .sub-price li {
    width: calc(33% - 45px);
    border: 1px solid #d1deee;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .sub-price-1 li {
    width: calc(50% - 45px);
    border: 1px solid #d1deee;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    min-height: 70px;
  }

  .bg-prijs {
    background-color: #116dcb;
    color: #fff;
    padding: 5px 8px;
    border-radius: 10px;
    font-size: 17px !important;
  }

  .content-section {
    margin: 50px 0;
  }

  .width-800 {
    width: 800px;
    max-width: 800px;
    margin: 70px auto 50px auto;
  }

  .content-section h3 {
    font-size: 30px;
  }

  .accordion h3 {
    font-size: 30px;
  }

  .sub-price-1 li span,
  .sub-price li span {
    display: table;
    background-color: #ececec;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .accordion .toggle {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 12px;
    border: 1px solid #d1deee;
  }

  .accordion .toggle .header {
    /* width: 100%; */
    text-align: left;
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .accordion .toggle .header img {
    width: 15px;
    height: 15px;
    transition: transform 0.3s ease;
    transform: rotate(-90deg);
  }

  .accordion .toggle .header img.rotate {
    transform: rotate(0deg);
  }

  .accordion .toggle .content {
    padding: 15px;
    border-top: 1px solid #d1deee;
  }

  .animate {
    animation: AnimatieKeyframe 3s ease-in-out infinite;
  }

  @keyframes AnimatieKeyframe {
    0%,
    100% {
      transform: rotate(0deg);
    }
    50% {
      transform: translateX(10px) translateY(10px) translateZ(0) rotateZ(0deg);
    }
  }

  .contact {
    margin-top: 50px;
    padding: 30px;
    background-color: #116dcb;
    color: #fff;
    border-radius: 20px;
  }

  .contact a {
    color: #116dcb;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    text-decoration: none;
    font-weight: 600;
    display: inline-block;
    margin-top: 20px;
  }

  @media only screen and (max-width: 1000px) and (min-width: 450px) {
    .bg-hero {
      height: 465px !important;
    }
  }

  @media (max-width: 1000px) {
    main {
      max-width: 100%;
      width: 100%;
      padding: 0;
    }
    .bg-hero {
      height: 650px;
    }
    .hero {
      display: block;
    }
    .hero-section-2 {
      justify-content: end;
      margin-top: -40px;
    }
    .hero-image {
      width: 150px;
    }
    .heading-1 {
      margin: 0;
      font-size: 40px;
    }
    .heading-2 {
      font-size: 22px;
    }
    .content-section h3 {
      font-size: 25px;
    }
    .info-section {
      border-radius: 0;
      padding: 0;
      margin-top: 0px;
      background-color: unset;
      box-shadow: unset;
    }
    .accordion .toggle .header {
      font-size: 17px;
    }
    .accordion h3 {
      font-size: 25px;
    }
    .width-800 {
      width: 100%;
      max-width: 100%;
      margin: 50px auto 50px auto;
    }
    .font-size-2 {
      font-size: 25px;
    }
    .tarieven {
      flex-direction: column;
    }
    .tarief {
      margin-right: 0;
      margin-bottom: 30px;
    }
    .tarief ul li {
      font-size: 17px;
    }
    .margin-lr {
      margin: 0 0 30px 0;
    }
    .info-section {
      flex-direction: column;
    }
    .info-explain {
      margin-right: 0;
    }
    .info-dienst {
      padding: 30px 10px;
    }
    .scroll-wrapper {
      width: 107%;
    }
    .horizontal-scroll-container {
      gap: 15px;
    }
    .scroll-wrapper::before,
    .scroll-wrapper::after {
      width: 15px;
    }
    .scroll-wrapper::after {
      right: 17px;
    }
    .sub-price,
    .sub-price-1 {
      flex-direction: column;
    }
    .sub-price li,
    .sub-price-1 li {
      width: calc(100% - 30px);
    }
    .contact {
      width: 85%;
      padding: 25px;
    }
    .contact h3 {
      margin-top: 0;
    }
  }
</style>
