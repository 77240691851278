<!-- Modal.svelte -->
<script>
  import { showModal } from "../../scripts/modalStore.js";
</script>

{#if $showModal}
  <div class="modal">
    <div class="modal-content">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span class="close" on:click={() => showModal.set(false)}>&times;</span>
      <h2>Privacyverklaring</h2>
      <p>
        Alkebufin administratie is verantwoordelijk voor de verwerking van
        persoonsgegevens zoals weergegeven in deze privacyverklaring.
      </p>
      <h3>Contactgegevens</h3>
      <p>
        www.alkebufin.nl<br />
        MR. P.J. Oudsingel 83<br />
        6836 PL Arnhem <br />
        0642647251<br />
        info@alkebufin.nl
      </p>
      <h3>Persoonsgegevens die wij verwerken</h3>
      <p>
        Alkebufin administratie verwerkt uw persoonsgegevens doordat u gebruik
        maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt.
        Hieronder vindt u een overzicht van de persoonsgegevens die wij
        verwerken:
      </p>
      <ul>
        <li>Voor- en achternaam</li>
        <li>Geslacht</li>
        <li>Geboortedatum</li>
        <li>Geboorteplaats</li>
        <li>Adresgegevens</li>
        <li>Telefoonnummer</li>
        <li>E-mailadres</li>
        <li>Bankrekeningnummer</li>
      </ul>
      <h3>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h3>
      <p>
        Alkebufin administratie verwerkt de volgende bijzondere en/of gevoelige
        persoonsgegevens van u:
      </p>
      <ul>
        <li>
          Gegevens van personen jonger dan 16 jaar. Onze website en/of dienst
          heeft niet de intentie gegevens te verzamelen over websitebezoekers
          die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders
          of voogd. We kunnen echter niet controleren of een bezoeker ouder dan
          16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online
          activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over
          kinderen verzameld worden zonder ouderlijke toestemming. Als u ervan
          overtuigd bent dat wij zonder die toestemming persoonlijke gegevens
          hebben verzameld over een minderjarige, neem dan contact met ons op
          via info@alkebufin.nl, dan verwijderen wij deze informatie.
        </li>
        <li>Burgerservicenummer (BSN)</li>
      </ul>
      <h3>
        Met welk doel en op basis van welke grondslag wij persoonsgegevens
        verwerken
      </h3>
      <p>
        Alkebufin administratie verwerkt uw persoonsgegevens voor de volgende
        doelen:
      </p>
      <ul>
        <li>Het afhandelen van uw betaling</li>
        <li>Verzenden van onze nieuwsbrief</li>
        <li>
          U te kunnen bellen of e-mailen indien dit nodig is om onze
          dienstverlening uit te kunnen voeren
        </li>
        <li>U te informeren over wijzigingen van onze diensten en producten</li>
        <li>Om goederen en diensten bij u af te leveren</li>
        <li>
          Alkebufin administratie verwerkt ook persoonsgegevens als wij hier
          wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor
          onze belastingaangifte
        </li>
      </ul>
      <h3>Hoe lang we persoonsgegevens bewaren</h3>
      <p>
        Alkebufin administratie bewaart uw persoonsgegevens niet langer dan
        strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden
        verzameld. Wij hanteren de volgende bewaartermijnen:
      </p>
      <ul>
        <li>Jaarrekeningen, fiscale aangiften e.d. worden 7 jaar bewaard</li>
        <li>
          Permanente gegevens van cliënten, zoals bijvoorbeeld akten en
          overeenkomsten, worden bewaard tot 7 jaar na het beëindigen van de
          cliëntrelatie
        </li>
      </ul>
      <h3>Delen van persoonsgegevens met derden</h3>
      <p>
        Alkebufin administratie deelt uw persoonsgegevens met verschillende
        derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en
        om te voldoen aan een eventuele wettelijke verplichting.
      </p>
      <p>
        Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij
        een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van
        beveiliging en vertrouwelijkheid van uw gegevens. Alkebufin
        administratie blijft verantwoordelijk voor deze verwerkingen.
      </p>
      <h3>Gegevens inzien, aanpassen of verwijderen</h3>
      <p>
        U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te
        verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming
        voor de gegevensverwerking in te trekken of bezwaar te maken tegen de
        verwerking van uw persoonsgegevens door Alkebufin administratie. Tevens
        heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij
        ons een verzoek kunt indienen om de persoonsgegevens die wij van u
        beschikken in een computerbestand naar u of een ander, door u genoemde
        organisatie, te sturen. U kunt een verzoek tot inzage, correctie,
        verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot
        intrekking van uw toestemming of bezwaar op de verwerking van uw
        persoonsgegevens sturen naar info@alkebufin.nl. Om er zeker van te zijn
        dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van
        uw identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie
        uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan
        het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit
        ter bescherming van uw privacy. We reageren zo snel mogelijk, maar
        binnen vier weken, op uw verzoek.
      </p>
      <p>
        Alkebufin administratie wilt u er verder op wijzen dat u de mogelijkheid
        heeft om een klacht in te dienen bij de nationale toezichthouder, de
        Autoriteit Persoonsgegevens.
      </p>
      <h3>Hoe wij persoonsgegevens beveiligen</h3>
      <p>
        Alkebufin administratie neemt de bescherming van uw gegevens serieus en
        neemt passende maatregelen om misbruik, verlies, onbevoegde toegang,
        ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als
        u het idee heeft dat uw gegevens toch niet goed beveiligd zijn of er
        aanwijzingen zijn van misbruik, neem dan telefonisch contact met ons op
        of via info@alkebufin.nl.
      </p>

      <p>Laatste wijziging: februari 2024</p>
    </div>
  </div>
{/if}

<style>
  .modal {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #19335e;
    background-color: #0b192fe5;
  }
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    border-radius: 20px;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  .close {
    color: #19335e;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  li {
    font-size: 18px;
  }
  .close:hover,
  .close:focus {
    color: #116dcb;
    text-decoration: none;
    cursor: pointer;
  }
</style>
