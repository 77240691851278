<header>
  <a href="/"
    ><img
      src="../img/logo-alkebufin.svg"
      alt="Logo Alkebufin"
      class="logo"
    /></a
  >
  <nav>
    <a href="#diensten" class="nav-link">Diensten</a>
    <a href="#over-ons" class="nav-link">Over ons</a>
    <a href="#tarieven" class="nav-link">Tarieven</a>
    <a href="#faq" class="nav-link">FAQ</a>
    <!-- More links -->
    <a href="tel:0642647251"
      ><img
        src="../img/icon-phone-white.svg"
        alt="Bel Alkebufin"
        class="phone"
      /></a
    >
  </nav>
</header>

<style>
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
    margin: 1rem auto;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: #d1deee;
    background: #fff;
    width: 1280px;
    max-width: 1280px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .logo {
    width: 215px;
    padding: 1rem;
  }

  nav {
    display: flex;
    align-items: center;
    padding: 1rem 1.3rem;
  }

  .nav-link {
    margin-right: 2rem;
    font-size: 18px;
    color: #19335e;
  }

  .phone {
    background-color: #116dcb;
    width: 35px;
    padding: 7px;
    border-radius: 25px;
  }

  @media (max-width: 1000px) {
    .nav-link {
      display: none;
    }
    nav {
      padding: 1rem 25px;
      margin-bottom: -5px;
    }
    header {
      width: 100%;
      max-width: 100%;
      position: fixed;
      left: 0;
      transform: none;
      margin-bottom: 50px !important;
      border-radius: 0;
      top: -17px;
    }
    .logo {
      width: 170px;
      padding: 1rem 25px;
    }
    .phone {
      width: 30px;
    }
  }
</style>
